<template>
  <div id="detail-page">
    <v-card-title class="text-capitalize justify-center justify-lg-start primary--text pa-0 font-weight-bold">my details</v-card-title>
    <v-row>
        <v-col cols="12" class="col-lg-6">
            <v-row class="pa-0 ma-0 mb-5" align="center" justify="space-between">
                <v-btn type="button" color="secondary" large class="w-49 font-weight-bold rounded-lg" :outlined="model.details_type !== 'personal'" @click="model.details_type = 'personal'">Personal</v-btn>
                <v-btn type="button" color="secondary" large class="w-49 font-weight-bold rounded-lg" :outlined="model.details_type !== 'business'" @click="model.details_type = 'business';formValid()" rounded>business</v-btn>
            </v-row>
        </v-col>
    </v-row>
    <v-row>
        <v-col cols="12" class="col-lg-6 pa-0">
            <v-row class="ma-0 pa-0" justify="space-between">
                <v-col cols="12" class="col-lg-6">
                    <Input label="First Name*" :labelCls="{'error--text': valid.model.first_name.$dirty && !valid.model.first_name.required}">
                        <template v-slot:input>
                            <v-text-field label="First Name" solo name="First Name" ref="first_name" class="box-shadow-none rounded-lg text-capitalize" color="primary"
                                v-model.trim="firstName"  :error-messages="$helpers.errorMsg('name', valid.model.first_name, 'First Name')"
                                hide-details="auto" outlined required
                            />
                        </template>
                    </Input>
                </v-col>
                <v-col cols="12" class="col-lg-6">
                    <Input label="Last Name*" :labelCls="{'error--text': valid.model.last_name.$dirty && !valid.model.last_name.required}">
                        <template v-slot:input>
                            <v-text-field label="Last Name" solo name="Last Name" ref="last_name" class="box-shadow-none rounded-lg text-capitalize" color="primary"
                                v-model.trim="lastName"  :error-messages="$helpers.errorMsg('name', valid.model.last_name, 'Last Name')"
                                hide-details="auto" outlined required
                            />
                        </template>
                    </Input>
                </v-col>
            </v-row>
        </v-col>

        <v-col v-if="model.details_type === 'business'" cols="12" class="col-lg-6">
            <Input label="Business Name*" :labelCls="{'error--text': valid.model.sf_account_id.$dirty && !valid.model.sf_account_id.required}">
                <template v-slot:input>
                    <v-text-field name="Business Name" solo ref="sf_account_id" class="box-shadow-none rounded-lg text-capitalize" color="primary"  v-model="model.sf_account_id"
                        :error-messages="$helpers.errorMsg('name', valid.model.sf_account_id, 'Business Name')"
                        hide-details="auto" label="Business Name" outlined required
                    />
                </template>
            </Input>
        </v-col>

        <v-col v-else cols="12" class="col-lg-6">
            <Input label="Names to be used on-air">
                <template v-slot:input>
                    <v-text-field name="Other Name" solo class="box-shadow-none rounded-lg text-capitalize" color="primary"  v-model="model.donation_behalf"
                        hide-details="auto" label="e.g. Husband, Wife, Child(ren) or Other Name(s)" outlined
                    />
                </template>
            </Input>
        </v-col>

        <v-col cols="12" class="col-lg-6 pa-0">
            <Input classProps="pa-3" label="address*" :labelCls="{'error--text': valid.model.address1.$dirty && !valid.model.address1.required}">
                <template v-slot:input>
                    <v-text-field name="Address" solo ref="address1" id="search-address" class="box-shadow-none rounded-lg text-capitalize" color="primary"  v-model="model.address1"
                        :error-messages="$helpers.errorMsg('name', valid.model.address1, 'Address')"
                        hide-details="auto" label="Address" outlined required
                    />
                </template>
            </Input>

            <Input classProps="pa-3" label="Town/Suburb*" :labelCls="{'error--text': valid.model.suburb.$dirty && !valid.model.suburb.required}">
                <template v-slot:input>
                    <v-text-field name="Town/Suburb" solo ref="suburb" class="box-shadow-none rounded-lg text-capitalize" color="primary"  v-model="model.suburb"
                        :error-messages="$helpers.errorMsg('name', valid.model.suburb, 'Town/Suburb')" 
                        hide-details="auto" label="Town/Suburb" outlined requiredcamel
                    />
                </template>
            </Input>

            <v-row class="w-full ma-0" align="center" justify="space-between">
                <v-col cols="12" class="col-lg-4">
                    <Input label="Postcode*" :labelCls="{'error--text': valid.model.postcode.$dirty && !valid.model.postcode.required}">
                        <template v-slot:input>
                            <v-text-field name="Postcode" solo ref="postcode" class="box-shadow-none rounded-lg text-capitalize" color="primary"  v-model="model.postcode"
                                :error-messages="$helpers.errorMsg('name', valid.model.postcode, 'Postcode')"
                                hide-details="auto" label="Postcode" outlined required
                            />
                        </template>
                    </Input>
                </v-col>
                <v-col cols="12" class="col-lg-4">
                    <Input label="State/Territory*" :labelCls="{'error--text': valid.model.state.$dirty && !valid.model.state.required}">
                        <template v-slot:input>
                            <v-text-field name="State/Territory" solo ref="state" class="box-shadow-none rounded-lg text-capitalize" color="primary"  v-model="model.state"
                                :error-messages="$helpers.errorMsg('name', valid.model.state, 'State/Territory')"
                                hide-details="auto" label="State/Territory" outlined required
                            />
                        </template>
                    </Input>
                </v-col>
                <v-col cols="12" class="col-lg-4">
                    <Input label="Country*" :labelCls="{'error--text': valid.model.country.$dirty && !valid.model.country.required}">
                        <template v-slot:input>
                            <v-text-field name="Country" solo ref="country" class="box-shadow-none rounded-lg text-capitalize" color="primary"  v-model="model.country"
                                :error-messages="$helpers.errorMsg('name', valid.model.country, 'Country')"
                                hide-details="auto" label="Country" outlined required
                            />
                        </template>
                    </Input>
                </v-col>
            </v-row>
        </v-col>

        <v-col cols="12" class="col-lg-6 pa-0">
            <Input classProps="pa-3" v-if="model.details_type === 'business'" label="This Donation is On behalf of">
                <template v-slot:input>
                    <v-text-field name="Other Name" solo class="box-shadow-none rounded-lg text-capitalize" color="primary"  v-model="model.donation_behalf" 
                        hide-details="auto" label="e.g. Husband, Wife, Child(ren) or Other Name(s)" outlined
                    />
                </template>
            </Input>

            <Input classProps="pa-3" label="Phone Number*" :labelCls="{'error--text': valid.model.phone.$dirty && !valid.model.phone.required}">
                <template v-slot:input>
                    <v-text-field type="number" solo name="Phone Number" ref="phone" class="box-shadow-none rounded-lg text-capitalize" color="primary"  v-model="model.phone"
                        :error-messages="$helpers.errorMsg('name', valid.model.phone, 'Phone Number')"
                        hide-details="auto" label="Phone Number" outlined required
                    />
                </template>
            </Input>

            <Input classProps="pa-3" label="Email*" :labelCls="{'error--text': valid.model.email.$dirty && !valid.model.email.required}">
                <template v-slot:input>
                    <v-menu v-model="didYouMeanOpen" offset-y :disabled="!didYouMeanOpen" rounded="b-xl">
                        <template v-slot:activator="{ on, attrs }">
                            <v-text-field ref="email" solo name="Email" class="box-shadow-none rounded-lg text-capitalize" color="primary"  v-model="model.email" v-bind="attrs"
                                :error-messages="$helpers.errorMsg('email', valid.model.email, 'Email')" v-on="on" @input="experianEmailSuggest"
                                hide-details="auto" label="Email" outlined required @blur="experianEmailSuggest"
                            />
                        </template>
                        <v-list>
                            <v-list-item-title class="font-weight-bold pl-2">Did you mean:</v-list-item-title>
                            <v-list-item @click="model.email = item;didYouMeanOpen=false" class="pa-0 min-h-auto my-1" v-for="(item, index) in didYouMeanLists" :key="index">
                                <v-list-item-title class="secondary--text font-weight-bold pl-3">{{ item }}</v-list-item-title>
                            </v-list-item>
                        </v-list>
                    </v-menu>
                </template>
            </Input>
        </v-col>
    </v-row>
    <v-row>
        <v-col cols="12" class="col-lg-6">
            <v-checkbox hide-details="auto" dense class="subscribe-label" color="secondary" v-model="model.subscribe_news"
                on-icon="mdi-checkbox-intermediate" off-icon="mdi-checkbox-blank-outline"
                label="Subscribe to TheLight News" :false-value="0" :true-value="1"
            ></v-checkbox>
            <template v-if="!latestIncentiveLoading && currentIncentive && currentIncentive.optin == 1">
                <v-checkbox hide-details="auto" dense class="subscribe-label" color="secondary" v-model="model.opt_in"
                    on-icon="mdi-checkbox-intermediate" off-icon="mdi-checkbox-blank-outline"
                    :false-value="null" :true-value="currentIncentive.optin_val"
                    :label="currentIncentive ? currentIncentive.optin_desc || '' : ''"
                ></v-checkbox>
            </template>
        </v-col>
    </v-row>
  </div>
</template>

<script>
import _ from 'lodash';
import { mapActions, mapGetters } from 'vuex';

import { Input, Dropdown } from '@/components';

export default {
    name: 'Detail',
    props: ['valid', 'submitForm'],
    emits: ["updateRefs"],
    components: {
        Input,
        Dropdown
    },
    data() {
        return {
            emailValidLoading: false,
            didYouMeanLists: [],
            didYouMeanOpen: false,
        };
    },
    computed: {
        ...mapGetters(["currentIncentive", "latestIncentiveLoading"]),

        model: {
            get() {
                return this.$store.getters["model"];
            },
            set(newValue) {
                return this.$store.commit("updateModel", newValue);
            },
        },
        firstName: {
            get() {
                return this.model.first_name ? _.startCase(_.toLower(this.model.first_name)) : '';
            },
            set(newValue) {
                this.model.first_name = _.startCase(_.toLower(newValue.trim()));
            },
        },
        lastName: {
            get() {
                return this.model.last_name ? _.startCase(_.toLower(this.model.last_name)) : '';
            },
            set(newValue) {
                this.model.last_name = _.startCase(_.toLower(newValue.trim()));
            }
        }
    },
    watch: {
        'model.sf_account_id': {
            handler: function(value) {
                this.model.receipt_name = value;
            },
            deep: true,
            immediate: true
        }
    },
    created() {
        this.formValid = _.debounce(this.formValid, 200);
        this.experianEmailSuggest = _.debounce(this.experianEmailSuggest, 500);
    },
    mounted() {
        const searchFieldSelector = $('#search-address'),
            self = this;

        // Address Capture options and initialization
        const address = new ContactDataServices.address({
            layout: 'IntuitiveDefault',
            token: window.VUE_APP_EXPERIAN_API_KEY,
            elements: {
                input: searchFieldSelector[0],
                // Hard-coded country if only single country supported.
                countryList: {
                    value: 'AUS'
                },
            },
            placeholderText: 'Searching an address',
            applyFocus: false,
        });
        address.events.on("post-formatting-search", function(item){
            const address = item.address;
            self.model.address1 = `${address[0].addressLine1} ${address[1].addressLine2} ${address[2].addressLine3}`;
            self.model.suburb = address[3].locality;
            self.model.state = address[4].province;
            self.model.postcode = address[5].postalCode;
            self.model.country = address[6].country;
        });
        this.$emit('updateRefs', this.$refs);
    },
    methods: {
        ...mapActions(["getEmailSuggestion"]),

        formValid() {
            if(this.submitForm) {
                this.valid.model.sf_account_id.$touch();
            }
        },
        experianEmailSuggest() {
            this.didYouMeanOpen = false;
            this.didYouMeanLists = [];
            if (this.valid.model.email.email && this.model.email && !this.emailValidLoading) {
                this.emailValidLoading = true;
                this.getEmailSuggestion(this.model.email).then((resp) => {
                    if(resp && resp.result && resp.result.did_you_mean) {
                        this.didYouMeanLists = resp.result.did_you_mean || [];
                        if (resp.result.confidence !== 'verified' && this.didYouMeanLists.length) this.didYouMeanOpen = true;
                    }
                    this.emailValidLoading = false;
                }).catch(err => this.emailValidLoading = false);
            }
            if (this.valid.model.email.email && this.model.email && this.didYouMeanLists.length) this.didYouMeanOpen = true;
        }
    }
};
</script>
